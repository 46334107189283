import { differenceInDays, startOfDay, addDays, isBefore } from 'date-fns';

export const numberWithCommas = (x: number): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isNumeric = (number: string | number | null): boolean =>
  !isNaN(parseFloat(number as string)) && isFinite(number as number);

export const getRandomIntegerInRange = (min = 0, max = 10): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const convertKgToImperial = (
  weightKg: number | null,
): {
  weightLbs: number;
  weightSt: number;
  weightLbsRemainder: number;
} | null => {
  if (weightKg === null || !isNumeric(weightKg)) {
    return null;
  }

  const weightLbs = Math.round(weightKg * 2.20462 * 10) / 10;
  const weightSt = Math.floor(weightLbs / 14);
  const weightLbsRemainder = Math.round((weightLbs % 14) * 10) / 10;
  return {
    weightLbs,
    weightSt,
    weightLbsRemainder,
  };
};

export const convertCmToImperial = (
  heightCm: number | null,
): {
  heightInches: number;
  heightFt: number;
  heightInchesRemainder: number;
} | null => {
  if (heightCm === null || !isNumeric(heightCm)) {
    return null;
  }

  const heightInches = Math.round((heightCm / 2.54) * 10) / 10;
  const heightFt = Math.floor(heightInches / 12);
  const heightInchesRemainder = Math.round((heightInches % 12) * 10) / 10;
  return {
    heightInches,
    heightFt,
    heightInchesRemainder,
  };
};

export const convertFtAndInchToCm = (
  ft: number,
  inches: number,
): number | null => {
  if (!isNumeric(ft) || !isNumeric(inches)) {
    return null;
  }
  const cm = 30.48 * (ft + inches / 12);
  return Math.round(cm * 10) / 10;
};

export const convertStonesAndPoundsToKg = (
  stones: number,
  pounds: number,
): number | null => {
  if (!isNumeric(stones) || !isNumeric(pounds)) {
    return null;
  }
  const kg = 6.35029318 * (stones + pounds / 14);
  return Math.round(kg * 10) / 10;
};

/**
 * Rounds a number to a set number of decimal places.
 *
 * @param {number} num - The number to round.
 * @return {number} The number rounded to seven decimal places.
 */
export const roundToDecimalPlaces = (
  num: number,
  decimalPlaces: number,
): number => {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(num * multiplier) / multiplier;
};

/**
 * Calculates BMI. Rounds to 1 decimal place by default.
 * @param weightKg Weight in kg
 * @param heightCm Height in cm
 * @param decimalPlacesToRound Defaults to 1. Pass null for raw value. Pass other numbers for different rounding.
 * @returns BMI value, or null if inputs are invalid
 */
export const getBMI = (
  weightKg: number | null,
  heightCm: number | null,
  decimalPlacesToRound: number | null = 1, // Default to 1
): number | null => {
  if (!weightKg || !heightCm || !isNumeric(weightKg) || !isNumeric(heightCm)) {
    return null;
  }
  const heightMeters = heightCm / 100;
  const rawBmi = weightKg / (heightMeters * heightMeters);

  if (decimalPlacesToRound === null) {
    return rawBmi;
  }

  return roundToDecimalPlaces(rawBmi, decimalPlacesToRound);
};

/**
 * Calculates the current streak and best streak based on an array of readings.
 *
 * @param readings - An array of readings containing the date, current streak flag, and best streak flag.
 * @returns An object with the current streak and best streak.
 */
export const getStreaks = (
  readings: { date: Date; currentStreak: boolean; bestStreak: boolean }[],
): { currentStreak: number; bestStreak: number } => {
  const { length } = readings;

  // If no readings, streaks are 0
  if (length === 0) {
    return {
      currentStreak: 0,
      bestStreak: 0,
    };
  }

  let currentStreak = 0;
  let bestStreak = 0;
  let streak = 1;
  let lastReading = startOfDay(readings[length - 1].date);
  const today = startOfDay(new Date());

  // Iterate through readings from the second to last to the first
  for (let i = length - 2; i >= 0; i--) {
    const reading = startOfDay(readings[i].date);
    const diff = differenceInDays(lastReading, reading);

    // If the readings are on consecutive days, increase the streak
    if (diff === 1) {
      streak++;
    } else if (diff > 1) {
      // If there's a gap, set the current streak if not already set and update best streak
      if (currentStreak === 0) {
        currentStreak = streak;
      }
      bestStreak = Math.max(bestStreak, streak);
      streak = 1; // Reset streak
    }

    // Update lastReading to the current reading
    lastReading = reading;
  }

  // Final check for current streak at the end of the loop
  if (currentStreak === 0) {
    currentStreak = streak;
  }
  bestStreak = Math.max(bestStreak, streak);

  // Check if the oldest reading wasn't today or yesterday, reset current streak if true
  if (isBefore(addDays(startOfDay(readings[length - 1].date), 1), today)) {
    currentStreak = 0;
  }

  // Set flags for current streak and best streak in the readings array
  readings.forEach((reading, i) => {
    reading.currentStreak = i >= length - currentStreak;
    reading.bestStreak = i >= length - bestStreak;
  });

  // Return the calculated streaks
  return {
    currentStreak,
    bestStreak,
  };
};
