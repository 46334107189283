import {
  getBMI,
  roundToDecimalPlaces,
} from '../../../../src/utils/numberUtils';
import { ReferralType } from '../state/state';
import { checkValueForError } from '../../../nextUtils/generalUtils';
import {
  HEIGHT_ERRORS,
  WEIGHT_ERRORS,
  NDPP_CONTRACTS,
} from '../constants/validation';
import { ErrorTracking } from '@common/errorTracking';

export const validateBMI = ({
  weightKg,
  heightCm,
  contractName,
}: {
  weightKg: number;
  heightCm: number;
  contractName?: string;
}): number => {
  const rawBmi = getBMI(weightKg, heightCm, null);

  if (rawBmi === null) {
    throw new Error(
      `Invalid input for BMI calculation: weightKg=${weightKg}, heightCm=${heightCm}`,
    );
  }

  const roundedBmi = roundToDecimalPlaces(rawBmi, 2);

  // Updated to 16 for NDPP as there is no minimum cutoff in the contract,
  // but we still need a min value to validate it's a valid BMI
  const BMI_CUTOFF =
    contractName && NDPP_CONTRACTS.includes(contractName) ? 16 : 21;

  if (rawBmi < BMI_CUTOFF) {
    ErrorTracking.addBreadcrumb({
      message: 'User BMI below threshold (raw) (calculated in validateBMI)',
      data: {
        bmi: rawBmi,
        roundedBmi,
        contractName,
        weightKg,
        heightCm,
        threshold: BMI_CUTOFF,
      },
      level: 'info',
    });
  }

  return rawBmi;
};

export const validateHeightAndWeight = (
  heightCm: number,
  weightKg: number,
  nhsReferral: ReferralType | null,
): {
  heightError: string;
  weightError: string;
  bmiError: string;
  hasValidHeightAndWeight: boolean;
} => {
  const heightError = checkValueForError(heightCm, HEIGHT_ERRORS) || '';
  const weightError = checkValueForError(weightKg, WEIGHT_ERRORS) || '';

  let bmiError = '';
  try {
    validateBMI({
      weightKg,
      heightCm,
      contractName: nhsReferral?.contract || '',
    });
  } catch (error) {
    bmiError = 'Could not calculate BMI due to invalid input.';
  }

  const hasValidHeightAndWeight = [heightError, weightError, bmiError].every(
    x => x === '' || x === null,
  );

  return { heightError, weightError, bmiError, hasValidHeightAndWeight };
};
